.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric-open {
  z-index: 9999;
}

  .selectric {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    padding: 4px 0px;
    position: relative;
    overflow: hidden;
  }

  .selectric .label {
    border-bottom: 1px dashed #3e8c32;
    white-space: nowrap;
    overflow: hidden;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
    .selectric:hover .label {
      color: #3e8c32;
    }

  .selectric .button {
    color: transparent;
    font-size: 0px;
    width: 7px;
    min-width: 7px;
    margin: 0px 0px 0px 6px;
    position: relative;
  }
    .selectric .button:after {
      content: "";
      display: block;
      position: absolute;
      background: url('/i/arrow-down--black.svg') no-repeat 0px 50%/contain transparent;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
    }

  .selectric-disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .selectric-hide-select {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;
  }

  .selectric-hide-select select {
    position: absolute;
    left: -100%;
  }

  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .selectric-hide-select.selectric-is-native select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: none;
    z-index: 1;
    box-sizing: border-box;
    opacity: 0;
  }

  .selectric-input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    outline: none !important;
    border: none !important;
    *font: 0/0 a !important;
    background: none !important;
  }

  .selectric-temp-show {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important;
  }

  /* Items box */
  .selectric-items {
    background: #fff;
    border-radius: 0px;
    box-shadow: 0 0px 10px 0px rgba(0,0,0,.175);
    position: absolute;
    width: auto !important;
    min-width: 100%;
    top: 100%;
    left: 0%;
    padding: 0px;
    margin: 0px 0px 0px -15px;
    z-index: -1;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: visibility 0s ease .2s, opacity .2s ease 0s;
    -moz-transition: visibility 0s ease .2s, opacity .2s ease 0s;
    -o-transition: visibility 0s ease .2s, opacity .2s ease 0s;
    transition: visibility 0s ease .2s, opacity .2s ease 0s;

    /*-webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);*/
  }
    .selectric-above .selectric-items {
      top: auto;
      bottom: 100%;
    }

    .selectric-open .selectric-items {
      visibility: visible;
      opacity: 1;

      -webkit-transition: visibility 0s ease 0s, opacity .2s ease 0s;
      -moz-transition: visibility 0s ease 0s, opacity .2s ease 0s;
      -o-transition: visibility 0s ease 0s, opacity .2s ease 0s;
      transition: visibility 0s ease 0s, opacity .2s ease 0s;
    }

    .selectric-items .selectric-scroll {
      height: 100%;
      overflow: auto;
    }

    .selectric-items ul,
    .selectric-items li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
      .selectric-items li {
        display: block;
        padding: 8px 18px 9px 15px;
        cursor: pointer;
        white-space: nowrap;
      }
      .selectric-items li + li {
        border-top: 1px solid #f0f0f0;
      }

        .selectric-items li:hover {
          color: #3e8c32;
        }
        .selectric-items li.selected {
          color: #50af47;
          font-weight: 600;
        }

        .selectric-items .disabled {
          filter: alpha(opacity=50);
          opacity: 0.5;
          cursor: default !important;
          background: none !important;
          color: #555 !important;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none;
        }

      .selectric-items .selectric-group .selectric-group-label {
        background: none;
        font-weight: bold;
        padding-left: 15px;
        cursor: default;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
      }

      .selectric-items .selectric-group.disabled li {
        filter: alpha(opacity=100);
        opacity: 1;
      }

      .selectric-items .selectric-group li {
        padding-left: 15px;
      }