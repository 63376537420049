/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fff;
  overflow-x: hidden;
}

.semibold, .semibold_a, .semibold_a * { font-weight: 600; }

span.required { color: #f00; font-weight: 700; }

.bgclgray, .bgclgray_h:hover { background-color: #fbfafb; }
.bgclgray_i, .bgclgray_h_i:hover { background-color: #fbfafb !important; }
.bgcgray, .bgcgray_h:hover { background-color: #bebebf; }
.bgcgray_i, .bgcgray_h_i:hover { background-color: #bebebf !important; }
.bgcgreen, .bgcgreen_h:hover { background-color: #50af47; }
.bgcgreen_i, .bgcgreen_h_i:hover { background-color: #50af47 !important; }

.cgray, .cgray_h:hover { color: #bebebf; }
.cgray_i, .cgray_h_i:hover { color: #bebebf !important; }
.cgreen, .cgreen_h:hover { color: #50af47; }
.cgreen_i, .cgreen_h_i:hover { color: #50af47 !important; }

.brcgray, .brcgray_h:hover { border-color: #bebebf; }
.brcgray_i, .brcgray_h_i:hover { border-color: #bebebf !important; }
.brcgreen, .brcgreen_h:hover { border-color: #50af47; }
.brcgreen_i, .brcgreen_h_i:hover { border-color: #50af47 !important; }

p { margin-bottom: 22px; }
p.before-ul { margin-bottom: 12px; }
p + p { margin-top: -8px; }
.subheader + p, .subsubheader + p, .subsubsubheader + p, .subsubsubheader-min + p { margin-top: -6px; }


.link {
  color: #3e8c32;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }


.form-block-container {
  background: #fff;
  padding: 30px;
}
  .form-popup-container .form-block-container {
    min-width: 400px;
    max-width: 700px;
  }

  .form-block-container .subsubheader {
    padding-top: 0px;
  }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 20px;
    }
      .form-label {
        margin-bottom: 8px;
      }
        .form-row-checkbox .form-label {
          font-size: 14px;
          margin: 0px 0px 0px 10px;
          cursor: pointer;
        }
          .form-row-checkbox .form-label label {
            cursor: pointer;
          }

    .form-row-checkbox .form-field-wrapper {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
      .form-row-checkbox .form-field input {
        display: none;
      }
      .form-row-checkbox .form-field > span {
        content: "";
        color: transparent;
        background: #f6f6f6;
        border: 1px solid #ccc;
        border-radius: 2px;
        font-size: 0px;
        line-height: 0px;
        display: block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        -webkit-transition: background-color .3s ease 0s, border-color .3s ease 0s;
        -moz-transition: background-color .3s ease 0s, border-color .3s ease 0s;
        -o-transition: background-color .3s ease 0s, border-color .3s ease 0s;
        transition: background-color .3s ease 0s, border-color .3s ease 0s;
      }
        .form-row-checkbox .form-field input:hover + span,
        .form-row-checkbox .form-field input:checked + span {
          background: #3e8c32;
          border-color: #3e8c32;
        }

        .form-row-checkbox .form-field > span label {
          content: "";
          color: transparent;
          display: block;
          font-size: 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          margin: auto;
          cursor: pointer;
        }
          .form-row-checkbox .form-field > span label:after {
            content: "";
            display: none;
            border: solid #fff;
            border-width: 0px 2px 2px 0px;
            width: 4px;
            height: 9px;
            position: absolute;
            top: -3px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            transform: rotate(45deg);
          }
            .form-row-checkbox .form-field input:checked + span label:after {
              display: block;
            }

      .input-container {
        background: #f0f0f0;
        border-radius: 5px;
        padding: 9px 10px;
        position: relative;
        z-index: 1;
      }
        .input-container input,
        .input-container textarea,
        .input-container select {
          color: #1d1d1d;
          background: transparent;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }

      .form-error {
        position: relative;
      }
        .errorMessage {
          color: #fff;
          background: #50af47;
          border-radius: 4px;
          font-size: 11px;
          line-height: 1.3;
          padding: 2px 5px 3px;
          margin: 2px 0px 0px;
        }

    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 8px 0px 0px -14px;
    }
      .form-button {
        padding: 14px 0px 0px 14px;
      }



.button-style {
  display: inline-block;
  color: #50af47;
  background: #fff;
  border: 1px solid #50af47;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  min-width: 100px;
  padding: 14px 20px;
  cursor: pointer;
  position: relative;

  -webkit-transition: color .3s ease 0s, background-color .3s ease 0s;
  -moz-transition: color .3s ease 0s, background-color .3s ease 0s;
  -o-transition: color .3s ease 0s, background-color .3s ease 0s;
  transition: color .3s ease 0s, background-color .3s ease 0s;
}
  .button-style[disabled],
  .button-style:disabled,
  .button-style.disabled {
    color: #959595 !important;
    background: #e7e7e7 !important;
    border-color: #959595 !important;
    cursor: default;
  }

  .button-style:hover,
  .button-style.active {
    color: #fff;
    background: #50af47;
    border-color: #50af47;
  }
    .button-style.active:hover {
      background: #3e8c32;
      border-color: #3e8c32;
    }

  .button-style > * {
    display: block;
  }


ul.ul, ol.ol {
  margin: -6px 0px 22px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 18px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  ul.ul li, ol.ol li {
    padding-top: 10px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li {
      padding-left: 20px;
    }
      ul.ul li:before {
        content: "●";
        color: transparent;
        display: inline-block;
        background: url('/i/ul-list-style-image.svg') no-repeat 50% 50%/contain transparent;
        width: 9px;
        margin: 0px 8px 0px -21px;
      }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      color: #50af47;
      font-weight: 600;
    }


.unwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
  .unwrapper > div {
    width: 100vw;
    min-width: 100vw;
  }

.wrapper {
  max-width: 1384px;
  margin: 0px auto;
  padding: 0px 20px;
  position: relative;
}



/* Structure */
  /* Header */
  .header {
    background: #fff;
    position: relative;
    z-index: 5;
  }
    .header-content {
      padding: 25px 0px;
    }
      .header-content > .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
        .header-logo {}


        .search-form-container {
          flex: 1 1 auto;
          max-width: 350px;
          padding-left: 35px;
        }
          .search-form-wrapper {
            position: relative;
          }
            .search-button-container {
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 1;
            }
              .search-button {
                display: inline-block;
                background: none;
                border: none;
                font-size: 0px;
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                min-width: 38px;
                padding: 0px;
                margin: 0px;
                cursor: pointer;
              }
                .search-button span {
                  color: transparent;
                  background: url('/i/header-icon-search.svg') no-repeat 50% 50%/14px auto transparent;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 1;
                }

                .search-button img {
                  height: 100%;
                }


          .header-address,
          .header-phones {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: 35px;
          }

          .header-address {
            font-size: 14px;
          }
            .header-address:before {
              content: "";
              background: url('/i/contact-icon-address.svg') no-repeat 50% 0%/contain transparent;
              display: block;
              min-width: 12px;
              min-height: 16px;
              margin: 1px 10px 0px 0px;
            }

            .header-address > div {
              align-self: center;
            }

          .header-phones {
            text-align: right;
          }
            .header-phones:before {
              content: "";
              background: url('/i/contact-icon-phone.svg') no-repeat 50% 0%/contain transparent;
              display: block;
              min-width: 16px;
              min-height: 16px;
              margin: 3px 8px 0px 0px;
            }

            .header-phone a {
              font-size: 16px;
              font-weight: 600;
              white-space: nowrap;
            }

            .header-phones .callback-link {
              margin: 1px 0px 0px;
            }
              .header-phones .callback-link a {
                font-size: 12px;
                display: inline-block;
                text-decoration: none;
                cursor: pointer;
                padding: 2px;

                -webkit-transition: color .3s ease 0s;
                -moz-transition: color .3s ease 0s;
                -o-transition: color .3s ease 0s;
                transition: color .3s ease 0s;
              }
                .header-phones .callback-link a:hover {
                  color: #50af47;
                }
                .header-phones .callback-link a div {
                  border-bottom: 1px dashed #2d2d2d;
                  line-height: 1.1;

                  -webkit-transition: border-color .3s ease 0s;
                  -moz-transition: border-color .3s ease 0s;
                  -o-transition: border-color .3s ease 0s;
                  transition: border-color .3s ease 0s;
                }
                  .header-phones .callback-link a:hover div {
                    border-color: #50af47;
                  }


          .header-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
          }
            .header-search-show-button {
              display: none;
              padding-left: 15px;
            }
              .header-search-show-button span {
                display: block;
                color: transparent;
                background: url(/i/header-icon-search.svg) no-repeat 50% 50%/25px auto transparent;
                font-size: 0px;
                width: 42px;
                min-width: 42px;
                height: 42px;
                cursor: pointer;
              }


            .header-basket-container {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: center;
              padding-left: 35px;
            }
              .header-basket-container a {
                cursor: pointer;
                text-decoration: none;
              }

              .header-basket-container > span:first-child {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-size: 13px;
                padding-left: 15px;
                white-space: nowrap;
              }
                .header-basket-container > span:first-child a:first-child,
                .header-basket-container > span:first-child span:first-child {
                  color: #808080;
                  font-weight: 600;
                }

                .header-basket-container > span:first-child a + a,
                .header-basket-container > span:first-child span + span {
                  color: #404040;
                  font-size: 15px;
                  font-weight: 700;
                  margin-top: 2px;
                }

              .header-basket-icon {
                display: block;
                background: url('/i/header-icon-cart--gray.svg') no-repeat 50% 50%/contain transparent;
                min-width: 30px;
                max-width: 30px;
                min-height: 30px;
                position: relative;

                -webkit-transition: background-image .3s ease 0s;
                -moz-transition: background-image .3s ease 0s;
                -o-transition: background-image .3s ease 0s;
                transition: background-image .3s ease 0s;
              }
                a.header-basket-icon {
                  background-image: url('/i/header-icon-cart--green.svg');
                }

                .header-basket-count {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: stretch;
                  color: #fff;
                  font-size: 11px;
                  line-height: 1.1;
                  font-weight: 500;
                  background: #3e8c32;
                  border: 2px solid #fff;
                  border-radius: 999px;
                  min-width: 22px;
                  padding: 3px 5px;
                  position: absolute;
                  top: -14px;
                  right: -12px;
                }
                  .header-basket-count > span {
                    display: none;
                  }


    .header-info-menu,
    .header-menu-wrapper {
      background: #fbfafb;
      border: solid #e8e8e8;
      border-width: 0px 0px 1px;
    }
    .header-menu-wrapper {
      border-width: 1px 0px 0px;
    }
      .header-menu-button-container,
      .header-menu-checkbox {
        display: none;
      }

      .header-menu {
        position: relative;
      }
        .header-menu ul > li {
          z-index: 0;
          position: relative;
        }
          .header-menu li > div a,
          .header-menu li > div span {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            line-height: 1.3;
            width: 100%;
            height: 100%;
            padding: 16px 10px;
            position: relative;
          }
          .header-info-menu .header-menu > ul > li > ul > li > div a,
          .header-info-menu .header-menu > ul > li > ul > li > div span {
            padding: 10px;
          }

          .header-menu li > div span {
            cursor: default;
          }
          .header-menu li > div a {
            cursor: pointer;
          }
            .header-menu li.active > div a {
              font-weight: 600;
              cursor: default;
            }
  /* Header end */



  /* Content block */
  .content {
    border-top: 1px solid #e8e8e8;
    position: relative;
    /*overflow: hidden;*/
    z-index: 3;

    /*min-height: 3000px;*/
  }
    .main-rotator-container {
      max-width: 1344px;
      padding: 0px 0px 25px;
      margin: -1px auto 0px;
      position: relative;
    }
      #main-rotator {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        position: relative;
        overflow: hidden;
      }
        #main-rotator .slick-list {
          width: 100%;
          height: 100%;
        }
        #main-rotator .slick-track {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          height: 100%;
        }

        .main-slide-container {
          display: flex !important;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          height: unset !important;
          position: relative;
        }
          #main-rotator > .main-slide-container {
            width: 100%;
            min-width: 100%;
          }

          .main-slide-image {
            width: 50%;
            position: relative;
            z-index: 1;
          }
            .main-slide-image > div {
              background: no-repeat 50% 50%/cover #eee;
              width: 200%;
              height: 100%;
              position: relative;
            }
              .main-slide-image > div > div {
                max-height: 600px;
              }
                .main-slide-image > div > div:before {
                  content: "";
                  display: block;
                  height: 0px;
                  padding: 60% 0% 0%;
                }

              .main-slide-image a {
                content: "";
                color: transparent;
                font-size: 0px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 1;
              }

          .main-slide-code-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            width: 50%;
          }
            .main-slide-code-container > div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              width: 200%;
              height: 100%;
              padding: 20px 116px 25px 85px;
            }
              .main-slide-title {
                color: #000;
                font-size: 32px;
                font-weight: 600;
                text-shadow: 0px 0px 12px rgba(255,255,255,.7);
                position: relative;
                z-index: 2;
              }
                .main-slide-title a {
                  color: #000;
                  text-decoration: none;
                  cursor: pointer;
                }

                .white-color .main-slide-title,
                .white-color .main-slide-title a {
                  color: #fff;
                }

              .main-slide-code {
                font-size: 17px;
                text-shadow: 0px 0px 12px rgba(255,255,255,.7);
                margin: 28px 0px 0px;
                position: relative;
                z-index: 2;
              }

              .white-color .main-slide-title,
              .white-color .main-slide-title a,
              .white-color .main-slide-code,
              .white-color .main-slide-code a {
                color: #fff;
                text-shadow: 0px 0px 12px rgba(0,0,0,.6);
              }

              .main-slide-button {
                margin: 35px 0px 0px;
                position: relative;
                z-index: 2;
              }
                .main-slide-button .button-style {
                  font-size: 16px;
                  font-weight: 400;
                  padding: 12px 30px;
                }


        #main-rotator-arrows {
          height: 0px;
          opacity: 0;

          -webkit-transition: opacity .3s ease 0s;
          -moz-transition: opacity .3s ease 0s;
          -o-transition: opacity .3s ease 0s;
          transition: opacity .3s ease 0s;
        }
          .main-rotator-container:hover #main-rotator-arrows {
            opacity: 1;
          }

          #main-rotator-arrows .slick-prev,
          #main-rotator-arrows .slick-next {
            font-size: 0px;
            background: rgba(255,255,255,.4);
            border-radius: 50%;
            width: 40px;
            height: 40px;
            margin: auto;
            cursor: pointer;
            position: absolute;
            top: 0px;
            left: 15px;
            bottom: 25px;
            z-index: 2;

            -webkit-transition: background-color .3s ease 0s;
            -moz-transition: background-color .3s ease 0s;
            -o-transition: background-color .3s ease 0s;
            transition: background-color .3s ease 0s;
          }
          #main-rotator-arrows .slick-next {
            left: auto;
            right: 15px;
          }
            #main-rotator-arrows .slick-prev:hover,
            #main-rotator-arrows .slick-next:hover {
              background-color: #fff;
            }

            #main-rotator-arrows .slick-prev:after,
            #main-rotator-arrows .slick-next:after {
              content: "";
              display: block;
              background: url('/i/arrow-left--black.svg') no-repeat 50% 50%/contain transparent;
              width: 8px;
              margin: auto;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 2px;
              bottom: 0px;
            }
            #main-rotator-arrows .slick-next:after {
              background-image: url('/i/arrow-right--black.svg');
              left: 1px;
              right: 0px;
            }


        #main-rotator-dots {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
          height: 0px;
        }
          #main-rotator-dots ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-content: flex-start;
            align-items: flex-start;
            padding: 20px;
          }
            #main-rotator-dots li {
              padding: 10px;
              position: relative;
            }
              #main-rotator-dots li div {
                background: transparent;
                border: 2px solid #fff;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                cursor: pointer;
                position: relative;
                z-index: 3;

                -webkit-transition: background-color .3s ease 0s;
                -moz-transition: background-color .3s ease 0s;
                -o-transition: background-color .3s ease 0s;
                transition: background-color .3s ease 0s;
              }
                #main-rotator-dots li.slick-active div {
                  background: #fff;
                }


    .breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      font-size: 12px;
      line-height: 1.1;
      padding: 23px 0px 0px;
      position: relative;
    }
      .breadcrumbs li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 2px 0px 0px;
      }
        .breadcrumbs li:after,
        .breadcrumbs a,
        .breadcrumbs span {
          line-height: 1.3;
          vertical-align: baseline;
        }
        .breadcrumbs li:after {
          content: "/ ";
          color: #9a9a9a;
          margin: auto 10px;
        }
          .breadcrumbs li:last-child:after {
            display: none;
          }

        .breadcrumbs a {
          color: #9a9a9a;
          cursor: pointer;
          text-decoration: none;

          -webkit-transition: color .3s ease 0s;
          -moz-transition: color .3s ease 0s;
          -o-transition: color .3s ease 0s;
          transition: color .3s ease 0s;
        }
          .breadcrumbs a:hover,
          .breadcrumbs span {
            color: #50af47;
          }


    .subheader,
    .subsubheader,
    .subsubheader-top {
      font-weight: 700;
      line-height: 1.2;
      padding: 20px 0px 35px;
      margin: 0px;
      position: relative;
      z-index: 2;
    }
      .subheader {
        font-size: 28px;
      }

      .subsubheader,
      .subsubheader-top {
        font-size: 24px;
      }
        .subheader + .subsubheader,
        .subsubheader-top {
          padding-top: 0px;
        }


    .text-container {
      padding: 0px 0px 25px;
      position: relative;
      z-index: 1;
    }


    .incut {
      border-left: 3px solid #50af47;
      padding: 10px 20px 10px 22px;
      margin: 10px 0px 35px;
    }

    .incut-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border: 2px solid #50af47;
      padding: 22px;
      margin: 10px 0px 35px;
    }
      .incut-info:before {
        content: "";
        background: url('/i/incut-icon-info.svg') no-repeat 50% 50%/contain transparent;
        min-width: 44px;
        min-height: 44px;
        margin: -10px 22px -10px 0px;
      }

    .incut-quote {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      background: rgba(80,175,71, 0.12);
      padding: 22px;
      margin: 10px 0px 35px;
    }
      .incut-quote:before {
        content: "";
        background: url('/i/incut-icon-quote.svg') no-repeat 50% 50%/contain transparent;
        min-width: 50px;
        min-height: 40px;
        margin: 0px 18px 0px 0px;
      }


    .tab-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: center;
      font-weight: 500;
      line-height: 1.3;
      padding: 0px 0px 25px;
      margin: -15px 0px 0px -30px;
      position: relative;
      z-index: 1;
    }
      .tab-list > div {
        padding: 15px 0px 0px 30px;
      }
        .tab-list a {
          display: block;
          padding: 5px 0px 8px;
          position: relative;

          -webkit-transition: color .3s ease 0s;
          -moz-transition: color .3s ease 0s;
          -o-transition: color .3s ease 0s;
          transition: color .3s ease 0s;
        }
          .tab-list .active a {
            color: #50af47;
          }

          .tab-list a:after {
            content: "";
            display: block;
            background: rgba(45,45,45,.1);
            height: 2px;
            position: absolute;
            left: 0px;
            right: 100%;
            bottom: 0px;

            -webkit-transition: right .3s ease 0s, background-color .3s ease 0s;
            -moz-transition: right .3s ease 0s, background-color .3s ease 0s;
            -o-transition: right .3s ease 0s, background-color .3s ease 0s;
            transition: right .3s ease 0s, background-color .3s ease 0s;
          }
            .tab-list a:hover:after {
              background: #2d2d2d;
              right: 0px;
            }
            .tab-list .active a:after {
              background: #50af47;
              right: 0px;
            }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      padding: 0px 0px 25px;
      margin: -24px 0px 0px -24px;
      position: relative;
    }
      .gallery-item {
        padding: 24px 0px 0px 24px;
        position: relative;
      }
        .gallery-item-width-1 {
          width: 100%;
        }
        .gallery-item-width-2 {
          width: 50%;
        }
        .gallery-item-width-3 {
          width: 33.33333333%;
        }
        .gallery-item-width-4 {
          width: 25%;
        }

        .gallery-item img {
          width: 100%;
        }

      .gallery-notice {
        margin: -8px 0px 0px !important;
        position: relative;
      }


    .advantages-block-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: center;
      padding: 0px 0px 25px;
      margin: -15px -30px 0px -50px;
    }
      .advantages-block-container > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 25%;
        padding: 15px 30px 0px 50px;
      }
        .advantages-block-container div > div:first-child {
          max-width: 55px;
          min-width: 55px;
        }
          .advantages-block-container div > div:first-child img {
            width: 100%;
          }

        .advantages-block-container div > div + div {
          color: #000;
          padding-left: 20px;
        }


    .list-view {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;

      padding: 0px 0px 25px;
      margin: -20px 0px 0px -20px;
      position: relative;
    }
      .list-view-item {
        width: 20%;
        padding: 20px 0px 0px 20px;
        position: relative;
      }
        .list-view-item.empty {
          font-weight: 700;
          width: 100% !important;
        }

        .list-view-item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          background: #fff;
          border: 1px solid #e8e8e8;
          border-radius: 5px;
          height: 100%;
          padding: 0px;
          position: relative;

          -webkit-transition: box-shadow .2s ease 0s;
          -moz-transition: box-shadow .2s ease 0s;
          -o-transition: box-shadow .2s ease 0s;
          transition: box-shadow .2s ease 0s;
        }
          .list-view-item-wrapper:hover {
            box-shadow: 0 2px 18px rgba(0,0,0,.12);
          }

          .list-view-item-image {
            position: relative;
            order: 1;
            z-index: 1;
          }
            .list-view-item-image > a,
            .list-view-item-image > span {
              display: block;
              background: no-repeat 50% 50%/cover #eaeaea;
              height: 0px;
              padding: 65% 0% 0%;
              position: relative;
              z-index: 1;
            }
              .list-view-item-image > a {
                cursor: pointer;
              }

          .list-view-item-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            height: 100%;
            padding: 20px;
            margin: 0px;
            order: 2;
            z-index: 2;
          }
            .list-view-item-name {
              font-size: 18px;
              font-weight: 600;
              text-decoration: none;
              position: relative;
              order: 2;
              z-index: 2;
            }
              .list-view-item-name a {
                cursor: pointer;

                -webkit-transition: color .3s ease 0s;
                -moz-transition: color .3s ease 0s;
                -o-transition: color .3s ease 0s;
                transition: color .3s ease 0s;
              }
                .list-view-item-name a:hover,
                .list-view-item-image:hover + .list-view-item-name a,
                .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                  color: #50af47;
                }

            .list-view-item-notice {
              margin: 15px 0px 0px;
              order: 3;
            }
              .list-view-item-notice :last-child {
                margin-bottom: 0px;
              }


    .product-section-block-container .list-view {
      padding: 1px 0px 25px 1px;
      margin: 0px;
    }
      .product-section-block-container .list-view-item {
        width: calc(100% / 7);
        padding: 0px;
        margin: -1px 0px 0px -1px;
      }
        .product-section-block-container .list-view-item:hover {
          z-index: 1;
        }

        .product-section-block-container .list-view-item-wrapper {
          border-radius: 0px;
          padding: 15px 15px 0px;
          z-index: 0;
        }
          .product-section-block-container .list-view-item-image > a {
            background-color: transparent;
            padding: 75% 0% 0%;
          }

          .product-section-block-container .list-view-item-text {
            display: block;
            padding: 15px 0px 25px;
          }
            .product-section-block-container .list-view-item-name {
              font-size: 15px;
              text-align: center;
              margin: 0px
            }


    .product-subsections {
      padding: 0px 0px 25px;
    }
      .product-subsections ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: -9px 0px 0px -9px;
      }
        .product-subsections li {
          padding: 9px 0px 0px 9px;
        }
          .product-subsections a {
            display: block;
            border: 1px solid #ece8e8;
            font-weight: 500;
            line-height: 1.1;
            text-decoration: none;
            padding: 8px 10px;
            cursor: pointer;

            -webkit-transition: color .3s ease 0s, box-shadow .3s ease 0s;
            -moz-transition: color .3s ease 0s, box-shadow .3s ease 0s;
            -o-transition: color .3s ease 0s, box-shadow .3s ease 0s;
            transition: color .3s ease 0s, box-shadow .3s ease 0s;
          }
            .product-subsections a:hover {
              color: #50af47;
              box-shadow: 0px 4px 16px rgba(27,30,37,.08);
            }


    .product-block-container {
      padding: 0px 0px 25px;
      position: relative;
      z-index: 3;
    }
      .product-block-container .list-view {
        padding: 1px 0px 0px 1px;
        margin: 0px;
        opacity: 1;

        -webkit-transition: opacity .3s ease-in-out 0s;
        -moz-transition: opacity .3s ease-in-out 0s;
        -o-transition: opacity .3s ease-in-out 0s;
        transition: opacity .3s ease-in-out 0s;
      }
        #product-list .list-view-loading .list-view {
          opacity: 0.2;
        }

        .product-block-container .list-view-item {
          padding: 0px;
          margin: -1px 0px 0px -1px;
        }
          .product-filters-wrapper + #product-list .list-view-item {
            width: 25%;
          }

          .product-block-container .list-view-item:hover {
            z-index: 1;
          }

          .product-block-container .list-view-item-wrapper {
            border-radius: 0px;
            z-index: 0;
          }
            .product-block-container .list-view-item-wrapper:hover {
              margin: -10px;
              padding: 0px 0px 10px;
            }

            .product-block-container .list-view-item-wrapper:before {
              content: "";
              background: #fff;
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 2;
            }

            .product-block-container .list-view-item-image {
              margin: 20px 20px 0px;
              z-index: 3;
            }
              .product-block-container .list-view-item-wrapper:hover .list-view-item-image {
                margin: 30px 30px 0px;
              }

              .product-block-container .list-view-item-image > a {
                background-color: transparent;
                background-size: contain;
                padding-top: 75%;
              }
                .product-block-container .list-view-item-image > a img {
                  display: none;
                }

            .product-block-container .list-view-item-wrapper:hover .list-view-item-text {
              padding: 20px 30px 20px;
              z-index: 3;
            }
              .product-block-container .list-view-item-name {
                font-size: 14px;
                font-weight: 400;
              }

              .product-block-container .list-view-item-name + div {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: stretch;
                height: 100%;
                order: 3;
              }
                .product-view-item-price {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  align-items: stretch;
                  padding: 16px 0px 0px;
                }
                  .product-view-item-price > div {
                    color: #404040;
                    font-size: 18px;
                    font-weight: 500;
                    vertical-align: baseline;
                  }

                  .product-view-item-price > div + div {
                    /*color: #b0b0b0;*/
                    font-size: 14px;
                    font-weight: 400;
                    /*text-decoration: line-through;*/
                    padding: 12px 0px 0px;
                  }
                    .product-view-item-price > div > span:first-child {
                      display: none;
                    }


                .product-economy {
                  padding: 15px 0px 0px;
                }
                  .product-economy div {
                    display: inline-block;
                    color: #404040;
                    background: #fbcd56;
                    border-radius: 2px;
                    font-size: 11px;
                    font-weight: 600;
                    line-height: 1.2;
                    padding: 3px;
                  }
                    .product-economy div > span:first-child {
                      display: none;
                    }

                    .product-economy div > span + span {
                      display: inline-flex;
                      flex-direction: row;
                      justify-content: flex-start;
                      align-items: center;
                    }
                      .product-economy div > span + span:after {
                        content: "";
                        background: url('/i/product-economy-icon.svg') no-repeat 50% 50%/contain transparent;
                        min-width: 10px;
                        min-height: 10px;
                        margin-right: 5px;
                      }

                      .product-economy div > span + span span {
                        padding: 3px 4px;
                      }
                      .product-economy div > span + span span:first-child {
                        background: #fff;
                        border-radius: 2px;
                      }


            .product-amount-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
              padding: 15px 0px 0px;
              position: relative;
              z-index: 1;
            }
            .product-block-container .product-amount-container {
              background: #fff;
              border: 1px solid #e8e8e8;
              border-top-width: 0px;
              padding: 10px 0px 0px;
              margin: -10px -1px 0px;
              position: absolute;
              top: 100%;
              left: -10px;
              right: -10px;

              visibility: hidden;
              opacity: 0;

              -webkit-transition: visibility .2s ease 0s, opacity .2s ease 0s, box-shadow .2s ease 0s;
              -moz-transition: visibility .2s ease 0s, opacity .2s ease 0s, box-shadow .2s ease 0s;
              -o-transition: visibility .2s ease 0s, opacity .2s ease 0s, box-shadow .2s ease 0s;
              transition: visibility .2s ease 0s, opacity .2s ease 0s, box-shadow .2s ease 0s;
            }
              .product-block-container .list-view-item-wrapper:hover .product-amount-container {
                box-shadow: 0 2px 18px rgba(0,0,0,.12);
                padding: 20px 0px 0px;
                left: 0px;
                right: 0px;
                visibility: visible;
                opacity: 1;
              }

              .product-amount-container > div {
                flex: 1 1 auto;
              }
                .product-amount-container .button-style {
                  display: inline-flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  padding: 15px 5px;
                  white-space: nowrap;
                }
                  .product-amount-container .button-style.already-in-basket {
                    color: #404040;
                    background: #fff;
                  }
                    .product-amount-container .button-style.already-in-basket:hover {
                      background: #f0f0f0;
                    }

                .product-amount .input-container {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: stretch;
                  background: transparent;
                  border: solid #50af47;
                  border-width: 1px 0px 1px 1px;
                  border-radius: 0px;
                  height: 100%;
                  padding: 0px;
                }
                  .product-amount .input-container > div:first-child {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 1;
                    order: 2;
                    height: 100%;
                    position: relative;
                  }
                    .product-amount .input-container input {
                      color: #404040;
                      background: transparent;
                      border: 0px;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 1.4;
                      padding: 8px 0px;
                      margin: 0px;
                      text-align: center;
                      order: 2;
                    }

                  .product-amount .ui-spinner-button {
                    width: 0px;
                    cursor: pointer;
                    position: relative;
                    z-index: 1;
                  }
                    .product-amount .ui-spinner-button div {
                      background: no-repeat 50% 50%/12px auto transparent;
                      display: inline-block;
                      min-width: 45px;
                      height: 100%;
                      position: relative;

                      -webkit-transition: background-color .3s ease 0s;
                      -moz-transition: background-color .3s ease 0s;
                      -o-transition: background-color .3s ease 0s;
                      transition: background-color .3s ease 0s;
                    }
                      .product-amount .ui-spinner-button div:hover {
                        background-color: #f7f7f7;
                      }

                      .product-amount .ui-spinner-button img {
                        height: 100%;
                      }

                  .product-amount .ui-spinner-down {
                    order: 1;
                  }
                    .product-amount .ui-spinner-down div {
                      background-image: url('/i/amount-icon--minus--black.svg');
                    }

                  .product-amount .ui-spinner-up {
                    order: 3;
                  }
                    .product-amount .ui-spinner-up div {
                      background-image: url('/i/amount-icon--plus--black.svg');

                      -webkit-transform: translateX(-100%);
                      -moz-transform: translateX(-100%);
                      -ms-transform: translateX(-100%);
                      -o-transform: translateX(-100%);
                      transform: translateX(-100%);
                    }


            .product-labels {
              display: flex;
              flex-flow: row wrap;
              justify-content: flex-start;
              align-items: flex-start;
              position: absolute;
              left: 0px;
              right: 4px;
              bottom: 0px;
            }
              .product-labels > div {
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.2;
                background: #f8971a;
                border-radius: 2px;
                padding: 5px 6px;
                margin: 4px 0px 0px 4px;
                position: relative;
                z-index: 3;
              }
              .product-labels > div.novelty {
                background: #3bb273;
              }
              .product-labels > div.recommend {
                background: #e15554;
              }
              .product-labels > div.discount {
                background: #7768ae;
              }


    .product-list-full {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;
      margin: 0px 0px 0px -20px;
      position: relative;
      z-index: 3;
    }
      .product-filters-wrapper {
        width: 20%;
        min-width: 20%;
        padding: 0px 0px 25px 20px;
        position: relative;
      }
        #product-filters {
          border: 1px solid #ece8e8;
          position: relative;
        }
          .product-filters-title {
            background: #fbfcfd;
            font-size: 15px;
            font-weight: 600;
            padding: 15px;
          }

          .filter-container {
            position: relative;
          }
            .filter-elements-container > div {
              border-top: 1px solid #ece8e8;
            }
              .filter-dropdown-button {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;
                line-height: 1.1;
                padding: 15px;
                cursor: pointer;
                position: relative;
                z-index: 1;
              }
                .filter-dropdown-button:after,
                .is-opened.is-opening > .filter-dropdown-button:after {
                  content: "";
                  background: url('/i/amount-icon--plus--black.svg') no-repeat 50% 50%/contain transparent;
                  width: 10px;
                  min-width: 10px;
                  height: 10px;
                  margin-left: 10px;
                }
                  .is-opened > .filter-dropdown-button:after,
                  .is-opening > .filter-dropdown-button:after {
                    background-image: url('/i/amount-icon--minus--black.svg');
                  }

                .filter-dropdown-button.is-selected {
                  color: #50af47;
                  font-weight: 600;
                }

            .filter-dropdown-container {
              display: none;
              padding: 0px 15px 15px;
              position: relative;
            }
              .is-opened > .filter-dropdown-container {
                display: block;
              }

              .filter-checkbox-list {
                margin-top: -8px;
              }
                .filter-checkbox-field {
                  margin-top: 8px;
                }
                  .filter-checkbox-field > input { display: none; }

                  .filter-checkbox-field > label {
                    display: inline-flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                  }
                    .filter-checkbox-field > input.disabled + label {
                      color: #959595;
                      cursor: default;
                    }

                    .filter-checkbox-field > label > span {
                      content: "";
                      color: transparent;
                      background: #f6f6f6;
                      border: 1px solid #ccc;
                      border-radius: 2px;
                      font-size: 0px;
                      line-height: 0px;
                      display: block;
                      width: 20px;
                      min-width: 20px;
                      height: 20px;
                      margin-right: 10px;
                      position: relative;
                      cursor: pointer;

                      -webkit-transition: background-color .3s ease 0s, border-color .3s ease 0s;
                      -moz-transition: background-color .3s ease 0s, border-color .3s ease 0s;
                      -o-transition: background-color .3s ease 0s, border-color .3s ease 0s;
                      transition: background-color .3s ease 0s, border-color .3s ease 0s;
                    }
                      .filter-checkbox-field > input:not(:disabled):hover + label > span,
                      .filter-checkbox-field > input:checked + label > span {
                        background: #3e8c32;
                        border-color: #3e8c32;
                      }
                        .filter-checkbox-field > label > span:after {
                          content: "";
                          display: none;
                          border: solid #fff;
                          border-width: 0px 2px 2px 0px;
                          width: 4px;
                          height: 9px;
                          position: absolute;
                          top: -3px;
                          left: 0px;
                          right: 0px;
                          bottom: 0px;
                          margin: auto;
                          transform: rotate(45deg);
                        }
                          .filter-checkbox-field > input:checked + label > span:after {
                            display: block;
                          }


              .filter-slider-inputs {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
              }
                .filter-slider-inputs .filter-slider-input-label-min { display: none; }
                .filter-slider-inputs .filter-slider-input-label-max {
                  content: "";
                  font-size: 0px;
                  color: transparent;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                }
                  .filter-slider-inputs .filter-slider-input-label-max:after {
                    content: "—";
                    font-size: 14px;
                    background: #828282;
                    height: 1px;
                    margin: 0px 4px;
                  }

                .filter-slider-inputs > div {
                  width: 50%;
                }
                  .filter-slider-inputs .input-container {
                    padding: 4px 8px;
                  }
                    .filter-slider-inputs .input-container input {
                      line-height: 1;
                    }

              .filter-slider-container {
                display: none;
                padding: 20px 0px 5px;
                position: relative;
              }
                .filter-slider-container.is-created {
                  display: block;
                }

                .filter-slider-container > div {
                  background: #ddd;
                  border-radius: 999px;
                  height: 2px;
                  margin: 0px 10px;
                  position: relative;
                }

                  .filter-slider-container > div > div {
                    background: #50af47;
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                  }

                  .filter-slider-container > div > span {
                    display: block;
                    background: #fff;
                    border-radius: 50%;
                    box-shadow: 0 3px 10px 1px rgba(0,0,0,.35);
                    position: absolute;
                    top: -9px;
                    bottom: -9px;
                    width: 20px;
                    margin-left: -10px;
                    cursor: pointer;
                  }
                    .filter-slider-container > div > span > span { display: none; }


            .filter-buttons-container {
              border-top: 1px solid #e7e7e7;
              line-height: 1.1;
              padding: 10px 10px 20px 0px;
            }
              .filter-buttons-container > div {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: center;
              }
                .filter-buttons-container > div > div {
                  text-align: center;
                  padding: 10px 0px 0px 10px;
                }
                  .filter-buttons-container .button-style {
                    font-size: 12px;
                    min-width: unset;
                    width: 100%;
                    padding: 10px;
                  }

                  .filter-buttons-container .filter-button-reset .button-style {
                    color: #959595;
                    background: transparent;
                    border-color: #e7e7e7;
                  }


        .product-filters-wrapper > div + div {
          padding-top: 25px;
        }
          .product-filters-wrapper > div + div > div > .subsubheader {
            font-size: 18px;
            font-weight: 500;
            padding-bottom: 25px;
          }



      #product-list {
        width: 100%;
        padding-left: 20px;
      }
        .pager-block-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          line-height: 1.2;
          padding: 0px 0px 18px;
          position: relative;
          z-index: 2;
        }
          .list-view + .pager-block-container {
            padding: 18px 0px 0px;
            z-index: 0;
          }

          .pager-block-container > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
            .sorting-container,
            .pagesize-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: baseline;
              padding: 0px 15px;
            }
              .sorting-block-label,
              .pager-block-label {
                padding: 0px 8px 1px 0px;
              }

              .sorting-container .input-container,
              .pagesize-container .input-container {
                background: transparent;
                border: none;
                padding: 0px;
              }


              .pager-container {
                width: 100%;
              }
                .pager-block-container ul.pager {
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: center;
                  align-items: stretch;
                  width: 100%;
                  margin: -8px 0px 0px -8px;
                }
                  .pager-block-container ul.pager > li {
                    margin: 8px 0px 0px 8px;
                  }
                    .pager-block-container ul.pager > li > a {
                      display: inline-flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      background: transparent;
                      border-radius: 4px;
                      min-width: 32px;
                      min-height: 32px;
                      padding: 6px;

                      -webkit-transition: background-color .3s ease 0s;
                      -moz-transition: background-color .3s ease 0s;
                      -o-transition: background-color .3s ease 0s;
                      transition: background-color .3s ease 0s;
                    }
                      .pager-block-container ul.pager > li > a:hover {
                        background: #f7f7f7;
                      }
                      .pager-block-container ul.pager > li.active > a {
                        color: #fff;
                        background: #50af47;
                        font-weight: 700;
                      }

                    .pager-block-container ul.pager > li.first,
                    .pager-block-container ul.pager > li.last {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: baseline;
                    }
                      .pager-block-container ul.pager > li.first:after,
                      .pager-block-container ul.pager > li.last:before {
                        content: "\2026";
                      }
                      .pager-block-container ul.pager > li.first:after {
                        margin: 0px 4px 0px 10px;
                      }
                      .pager-block-container ul.pager > li.last:before {
                        margin: 0px 10px 0px 4px;
                      }

                    .pager-block-container ul.pager > li.previous > a,
                    .pager-block-container ul.pager > li.next > a {
                      content: "";
                      font-size: 0px;
                      background-repeat: no-repeat;
                      background-position: 50% 50%;
                      background-size: 6px auto;
                    }
                    .pager-block-container ul.pager > li.previous > a {
                      background-image: url('/i/arrow-left--black.svg');
                    }
                      .pager-block-container ul.pager > li.previous.inactive > a {
                        cursor: default;
                        opacity: .2;
                      }
                    .pager-block-container ul.pager > li.next > a {
                      background-image: url('/i/arrow-right--black.svg');
                    }
                      .pager-block-container ul.pager > li.next.inactive > a {
                        cursor: default;
                        opacity: .2;
                      }


    .product-card {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px 0px 25px;
    }
      .product-gallery-container {
        min-width: 29.6875%;
        max-width: 29.6875%;
        position: relative;
      }
        .product-gallery-container .product-labels {
          top: -2px;
          bottom: unset;
        }

        .product-gallery {
          width: 100%;
          position: relative;
          overflow: hidden;
        }
          .product-gallery:before {
            content: "";
            display: block;
            height: 0px;
            padding: 75% 0% 0%;
          }
            .product-gallery.slick-initialized:before {
              display: none;
            }

            .product-gallery-image {
              position: relative;
              z-index: 1;
            }
              .product-gallery > .product-gallery-image {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
              }
                .product-gallery > .product-gallery-image:first-child {
                  z-index: 2;
                }

              .product-gallery-image > div {
                background: no-repeat 50% 50%/contain transparent;
                position: relative;
                z-index: 1;
              }
                .product-gallery-image > div:before {
                  content: "";
                  display: block;
                  font-size: 0px;
                  height: 0px;
                  padding: 75% 0% 0%;
                }

                .product-gallery-image > div a {
                  display: block;
                  color: transparent;
                  font-size: 0px;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  cursor: pointer;
                  z-index: 1;
                }

        .product-gallery-pager ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: flex-start;
          margin: 8px 0px 0px -6px;
        }
          .product-gallery-pager li {
            width: 20%;
            padding: 6px 0px 0px 6px;
          }
            .product-gallery-pager li > div {
              background: no-repeat 50% 50%/cover transparent;
              cursor: pointer;
              opacity: .4;

              -webkit-transition: opacity .3s ease 0s;
              -moz-transition: opacity .3s ease 0s;
              -o-transition: opacity .3s ease 0s;
              transition: opacity .3s ease 0s;
            }
              .product-gallery-pager li > div:after {
                content: "";
                display: block;
                height: 0px;
                padding: 75% 0% 0%;
              }

              .product-gallery-pager li > div:hover,
              .product-gallery-pager li.slick-active > div {
                opacity: 1;
              }


      .product-card-params {
        min-width: 35.9375%;
        max-width: 35.9375%;
        padding: 0px 0px 0px 40px;
      }
        .product-card-params .subsubheader-top {
          color: #404040;
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          padding: 0px 0px 12px;
        }

        .product-params {
          margin: -8px 0px 0px;
        }
          .product-params > div > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            padding: 8px 0px 0px;
          }
            .product-params > div > div:after {
              content: "—";
              margin: 0px 6px;
              order: 2;
              z-index: 0;
            }

            .product-params > div > div > span:first-child {
              flex: 0 0 auto;
              white-space: nowrap;
              order: 1;
              position: relative;
              z-index: 2;
            }
            .product-params > div > div > span + span {
              flex: 1 1 auto;
              order: 3;
              z-index: 1;
            }


      .product-order-container {
        min-width: 34.375%;
        max-width: 34.375%;
        padding: 4px 0px 0px 40px;
      }
        .product-order-container > div {
          background: #fff;
          box-shadow: 0 0 24px rgba(27,30,37,.08);
          padding: 30px;
        }
          .product-card-price {
            font-size: 22px;
            padding: 0px 0px 15px;
          }
            .product-card-price > div {
              vertical-align: baseline;
            }
              .product-card-price > div:first-child {
                font-weight: 700;
              }
              .product-card-price > div + div {
                color: #808080;
                font-size: 17px;
                padding: 12px 0px 0px;
              }

              .product-card-price > div span {
                vertical-align: baseline;
              }

                .product-card-price > div:first-child > div {
                  font-size: 18px;
                  font-weight: 500;
                  padding: 2px 0px 0px;
                }

                .product-card-price > div + div > div {
                  font-size: 14px;
                  padding: 2px 0px 0px;
                }

          .product-card .product-economy {
            padding: 0px 0px 15px;
          }
            .product-card .product-economy div {
              font-size: 13px;
            }

          .product-card .product-amount-container > div + div {
            flex-basis: 60%;
          }


          .product-price-text {
            font-size: 12px;
            border-top: 1px solid #e8e8e8;
            margin: 25px 0px 0px;
            padding: 22px 0px 0px;
          }
            .product-price-text :last-child {
              margin-bottom: 0px;
            }


    .product-content-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      margin: -25px 0px 0px -25px;
    }
      .product-content-container > div:first-child {
        flex: 897 0;
        margin: 25px 0px 0px 25px;
      }

      .product-content-container > div + div {
        flex: 422 0;
        margin: 25px 0px 0px 25px;
      }
        .product-small-block-container .list-view {
          display: block;
          padding: 1px 0px 25px;
          margin: 0px;
        }
          .product-small-block-container .list-view-item {
            width: unset;
            padding: 0px;
            margin: -1px 0px 0px;
          }
            .product-small-block-container .list-view-item:hover {
              z-index: 1;
            }

            .product-small-block-container .list-view-item-wrapper {
              flex-direction: row;
              align-items: center;
              border-radius: 0px;
              padding: 0px;
            }
              .product-small-block-container .list-view-item-image {
                align-self: flex-start;
                width: 20%;
                min-width: 20%;
                margin: 18px 0px 18px 18px;
              }
                .product-small-block-container .list-view-item-image > a {
                  background: no-repeat 50% 0%/contain transparent;
                  height: unset;
                  padding: 0px;
                }
                  .product-small-block-container .list-view-item-image > a:after {
                    content: "";
                    display: block;
                    height: 0px;
                    padding: 75% 0% 0%;
                  }

              .product-small-block-container .list-view-item-text {
                height: unset;
                padding: 18px 10px 18px 18px;
              }
                .product-small-block-container .list-view-item-name {
                  font-size: 13px;
                  font-weight: 400;
                  margin: 0px 0px 8px;
                }

                .product-small-block-container .product-view-item-price {
                  order: 3;
                  padding: 0px;
                }
                  .product-small-block-container .product-view-item-price > div {
                    font-size: 16px;
                    font-weight: 600;
                  }

                  .product-small-block-container .product-price-wrapper {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    align-items: baseline;
                    margin: -5px 0px 0px -12px;
                  }
                    .product-small-block-container .product-price-wrapper > div {
                      margin: 5px 0px 0px 12px;
                    }
                    .product-small-block-container .product-price-wrapper > div + div {
                      color: #b0b0b0;
                      font-size: 12px;
                      font-weight: 500;
                      text-decoration: line-through;
                      margin: 5px 0px 0px 12px;
                    }
                      .product-small-block-container .product-price-wrapper > div > span {
                        display: none;
                      }




    .basket-products {
      padding: 1px 0px 25px;
      width: 100%;
    }
      .basket-product-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        border: 1px solid #e8e8e8;
        margin: -1px 0px 0px;
        position: relative;

        -webkit-transition: box-shadow .2s ease 0s;
        -moz-transition: box-shadow .2s ease 0s;
        -o-transition: box-shadow .2s ease 0s;
        transition: box-shadow .2s ease 0s;
      }
        .basket-product-item:hover {
          box-shadow: 0 2px 18px rgba(0,0,0,.12);
          z-index: 1;
        }

        .basket-product-item > div {
          display: flex;
          flex-direction: row;
        }
        .basket-product-item > div:first-child {
          justify-content: flex-start;
          align-items: flex-start;
          flex: 1 0 0;
          padding: 25px 0px 25px 25px;
        }
          .basket-product-item .list-view-item-image {
            width: 14%;
            min-width: 14%;
            margin: 0px;
          }
            .basket-product-item .list-view-item-image a {
              background-color: transparent;
              background-size: contain;
              padding-top: 75%;
            }

          .basket-product-item .list-view-item-text {
            background: none;
            height: auto;
            padding: 10px 25px 0px 25px;
            margin: 0px;
          }
            .basket-product-item .list-view-item-name {
              font-size: 18px;
              font-weight: 600;
              margin: 0px;
            }
              .basket-product-item .list-view-item-name a {
                color: #1b1e25;
              }

            .basket-product-item .product-view-item-params {
              font-style: normal;
              text-align: left;
              margin-top: 0px;
            }

        .basket-product-item > div + div {
          justify-content: flex-end;
          align-items: center;
          flex: 1 0 0;
          padding: 25px 0px 25px 20px;
        }
          .basket-product-item > div + div > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin: -15px 0px 0px -20px;
          }
            .basket-product-item > div + div > div:first-child > div {
              padding: 15px 0px 0px 20px;
            }

            .basket-product-item .basket-product-price {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              min-width: 28%;
              white-space: nowrap;
            }
              .basket-product-item .basket-product-price > span:first-child {
                color: #404040;
                margin-bottom: 4px;
              }
              .basket-product-item .basket-product-price > span:first-child + span {
                color: #1b1e25;
                font-size: 20px;
                font-weight: 500;
              }
              .basket-product-item .basket-product-price > span:first-child + span + span {
                color: #808080;
                text-decoration: line-through;
                margin-top: 4px;
              }

            .basket-product-item .basket-product-amount {
              min-width: 34%;
            }
              .basket-product-item .basket-product-amount > span {
                display: none;
              }

              .basket-product-item .product-amount .input-container {
                background: #f0f0f0;
                border: 1px solid #e8e8e8;
              }

            .basket-product-item .basket-product-sum {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              min-width: 38%;
              white-space: nowrap;
              text-align: right;
            }
              .basket-product-item .basket-product-sum > span:first-child {
                color: #1b1e25;
                font-size: 22px;
                font-weight: 500;
                order: 2;
              }
              .basket-product-item .basket-product-sum > span:first-child + span {
                color: #404040;
                margin-bottom: 4px;
                order: 1;
              }
              .basket-product-item .basket-product-sum > span:first-child + span + span {
                color: #808080;
                text-decoration: line-through;
                margin-top: 4px;
                order: 3;
              }
              .basket-product-item .basket-product-sum > span:first-child + span + span + span {
                color: #808080;
                font-size: 12px;
                vertical-align: baseline;
                margin-top: 8px;
                order: 4;
              }
                .basket-product-item .basket-product-sum > span:first-child + span + span + span > span {
                  color: #1b1e25;
                  font-weight: 500;
                  display: inline-block;
                  background: #fbcd56;
                  border-radius: 4px;
                  vertical-align: baseline;
                  padding: 3px 4px 2px;
                }

          .basket-product-item .basket-product-delete {
            align-self: stretch;
            color: transparent;
            font-size: 0px;
            margin: -25px 0px -25px 20px;
          }
            .basket-product-item .basket-product-delete a {
              display: block;
              background: url('/i/cart-icon-delete.svg') no-repeat 50% 50%/18px auto #f2f2f2;
              width: 40px;
              min-width: 40px;
              height: 100%;
              position: relative;
              cursor: pointer;

              -webkit-transition: background-color .3s ease 0s;
              -moz-transition: background-color .3s ease 0s;
              -o-transition: background-color .3s ease 0s;
              transition: background-color .3s ease 0s;
            }
              .basket-product-item .basket-product-delete a:hover {
                background-color: rgba(80,175,71,0.12);
              }


      .basket-form .form-elements {
        padding: 0px 40px 40px;
        margin: 0px auto;
      }
        .basket-form .form-elements .form-row + .form-row {
          margin-top: 30px;
        }
          .basket-form .form-elements .form-row:not(.form-row-checkbox) .form-label {
            font-size: 16px;
            font-weight: 500;
          }


      .basket-sum-total {
        font-size: 16px;
        text-align: right;
        padding: 0px 0px 20px;
      }
        .basket-sum-total div:first-child {
          display: inline-block;
          vertical-align: baseline;
        }

        .basket-sum-total div + div {
          display: inline-block;
          font-size: 28px;
          font-weight: 700;
          white-space: nowrap;
          vertical-align: baseline;
          margin-left: 5px;
        }
          .basket-sum-total div + div span {
            font-size: 20px;
            font-weight: 400;
            vertical-align: baseline;
          }


    .basket-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 25px;
      margin: -10px 0px 0px -10px;
    }
      .basket-right-panel .basket-buttons-container {
        flex-direction: column;
        align-items: flex-start;
      }
      .basket-form .basket-buttons-container {
        flex-grow: 1;
      }

      .basket-buttons-container > div {
        padding: 10px 0px 0px 10px;
      }
        .basket-buttons-container .button-style {
          font-size: 16px;
        }

        .basket-buttons-container .return-button {
          font-size: 18px;
          border-bottom: 1px dashed #282828;
          line-height: 1.2;
          cursor: pointer;
        }
          .basket-buttons-container .return-button:hover {
            border-color: transparent;
          }

        .basket-buttons-container .goto-first-step-link a {
          display: inline-flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;
          font-size: 18px;
          text-decoration: none !important;
        }
          .basket-buttons-container .goto-first-step-link a:hover {
            text-decoration: underline !important;
          }


    .service-block-container .list-view-item {
      width: 25%;
    }
      .service-block-container .list-view-item-wrapper {
        border-radius: 0px;
      }
        .service-block-container .list-view-item-image a {
          padding: 80% 0% 0%;
        }

        .service-block-container .list-view-item-text {
          justify-content: flex-start;
        }

    .service-vitrine-container  .list-view-item {
      width: 25%;
    }
      .service-vitrine-container .list-view-item-wrapper {
        border: none;
        border-radius: 0px;
      }
        .service-vitrine-container .list-view-item-wrapper:hover {
          box-shadow: unset;
        }

        .service-vitrine-container .list-view-item-image {
          overflow: hidden;
        }
          .service-vitrine-container .list-view-item-image > a {
            padding-top: 125%;

            -webkit-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
            -moz-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
            -o-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
            transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
          }
            .service-vitrine-container .list-view-item-wrapper:hover .list-view-item-image > a {
              -webkit-transform: scale(1.2);
              -moz-transform: scale(1.2);
              -ms-transform: scale(1.2);
              -o-transform: scale(1.2);
              transform: scale(1.2);
            }

        .service-vitrine-container .list-view-item-text {
          display: block;
          height: unset;
          position: absolute;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: unset;
        }
          .service-vitrine-container .list-view-item-name {
            background: rgba(27,30,37,.7);
            font-size: 16px;
            font-weight: 500;
            padding: 8px 12px;
            margin-right: 30px;
          }

            .service-vitrine-container .list-view-item-name a,
            .service-vitrine-container .list-view-item-name a:hover,
            .service-vitrine-container .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
              color: #fff;
            }
  /* Content block end */



  /* Contact page */
  .contact-page-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    margin: 0px 0px 0px -45px;
  }
    .contact-page-info > div {
      padding: 0px 0px 25px 45px;
    }
      .contact-page-title {
        font-weight: 700;
        padding: 0px 0px 4px;
      }

      .contact-page-phones > div + div a {
        cursor: text;
        white-space: nowrap;
      }

      .contact-page-emails > div + div a {
        cursor: pointer;
        text-decoration: underline;
        white-space: nowrap;
      }
        .contact-page-emails > div + div a:hover {
          text-decoration: none;
        }


  .contact-page-map {
    padding: 0px 0px 25px;
    position: relative;
  }
    .contact-page-map > div {
      height: 0px;
      padding-top: 45%;
      position: relative;
    }
      .contact-page-map > div:before {
        content: "Загрузка карты...";
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
      }

      .contact-page-map > div > div {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 2;
      }
  /* Contact page end */



  /* Footer */
  .footer-wrapper {
    background: #f5f5f5;
  }
    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 25px 0px;
      margin: 0px 0px 0px -50px;
      position: relative;
      z-index: 1;
    }
      .footer-menu {
        flex: 7 0 0;
      }
        .footer-menu > ul {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          white-space: nowrap;
        }
          .footer-menu > ul > li {
            width: 100%;
            padding: 0px 0px 0px 50px;
          }
            .footer-menu a,
            .footer-menu span {
              display: inline-block;
              padding: 5px 0px;
              text-decoration: none;
            }
              .footer-menu a {
                cursor: pointer;
                text-decoration: none;

                -webkit-transition: color .2s ease 0s;
                -moz-transition: color .2s ease 0s;
                -o-transition: color .2s ease 0s;
                transition: color .2s ease 0s;
              }
              .footer-menu a:hover,
              .footer-menu .active a {
                color: #3e8c32;
              }
              .footer-menu a:hover {
                text-decoration: underline;
              }
              .footer-menu .active a {
                font-weight: 500;
                cursor: default;
                text-decoration: none;
              }

              .footer-menu > ul > li > div > div > span {
                font-size: 16px;
                font-weight: 700;
                padding: 0px 0px 15px;
              }

        .footer-contact {
          padding-left: 50px;
        }
          .footer-contact-title {
            font-size: 16px;
            font-weight: 700;
          }

          .footer-contact > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
          }

          .footer-contact > div + div {
            margin-top: 15px;
          }
            .footer-address:before {
              content: "";
              background: url(/i/contact-icon-address.svg) no-repeat 50% 0%/contain transparent;
              display: block;
              min-width: 12px;
              min-height: 16px;
              margin: 1px 12px 0px 0px;
            }
            .footer-address > div {
              align-self: center;
            }

            .footer-phones:before {
              content: "";
              background: url(/i/contact-icon-phone.svg) no-repeat 50% 0%/contain transparent;
              display: block;
              min-width: 16px;
              min-height: 16px;
              margin: 3px 8px 0px 0px;
            }
              .footer-phone + div {
                padding-top: 5px;
              }
                .footer-phone a {
                  font-size: 16px;
                  font-weight: 600;
                  cursor: text;
                  text-decoration: none;
                }

                .footer-phones .callback-link .button-style {
                  padding: 10px 20px;
                }


            .footer-emails:before {
              content: "";
              background: url(/i/contact-icon-mail.svg) no-repeat 50% 0%/contain transparent;
              display: block;
              min-width: 16px;
              min-height: 16px;
              margin: 4px 8px 0px 0px;
            }
              .footer-email {
                font-weight: 500;
              }
              .footer-email + div {
                padding-top: 5px;
              }


      .footer-bottom {
        border-top: 1px solid #e7e7e7;
        padding: 20px 0px;
      }
        .footer-bottom > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          margin: -15px 0px 0px -20px;
        }
          .footer-bottom > div > div {
            flex: 1 1 auto;
            padding: 15px 0px 0px 20px;
          }
  /* Footer end */
/* Structure end */